import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ApexTheme,
  ApexTooltip,
  ApexXAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { ApiService } from 'src/app/services/api/api.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  colors: string[];
  dataLabels: ApexDataLabels;
  fill: ApexFill;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  theme: ApexTheme;
};

@Component({
  selector: 'app-institution-details',
  templateUrl: './institution-details.component.html',
  styleUrls: ['./institution-details.component.scss']
})
export class InstitutionDetailsComponent implements OnInit, OnDestroy {
  @Input() trafficData!: any;
  @Input() userId!: number;

  @Output() closeModal = new EventEmitter<void>();
  @Output() dismissModal = new EventEmitter<void>();
  @Output() changeView = new EventEmitter<void>();

  @ViewChild('conversionsChart1') conversionsChart1!: ChartComponent;
  @ViewChild('conversionsChart2') conversionsChart2!: ChartComponent;
  conversion1!: Partial<ChartOptions>;
  conversion2!: Partial<ChartOptions>;
  conversion1Color = ['#73b4ff'];
  conversion2Color = ['#59e0c5'];
  dataSeries1: number[] = [];
  dataSeries2: number[] = [];
  intervalSub: any;
  services: any[] = [];
  service: any;
  nodeId!: number;
  interface: string = '';
  traffic: any[] = [];
  upload: string = '';
  download: string = '';
  status: string = '';
  isLoading: boolean = false;
  institution: any;

  constructor(private _api: ApiService) {
    this.dataSeries1 = this.getInitialSeries();
    this.dataSeries2 = this.getInitialSeries();

    this.conversion1 = {
      chart: {
        type: 'bar',
        height: 65,
        sparkline: {
          enabled: true,
        },
        background: 'transparent',
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0,
          gradientToColors: ['#4099ff'],
          inverseColors: true,
          opacityFrom: 0.99,
          opacityTo: 0.99,
          stops: [0, 100],
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '80%',
        },
      },
      series: [
        {
          data: this.dataSeries1,
        },
      ],
      xaxis: {
        crosshairs: {
          width: 1,
        },
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        marker: {
          show: false,
        },
      },
      theme: {
        mode: 'light',
      },
    };

    this.conversion2 = {
      chart: {
        type: 'bar',
        height: 65,
        sparkline: {
          enabled: true,
        },
        background: 'transparent',
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0,
          gradientToColors: ['#59e0c5'],
          inverseColors: true,
          opacityFrom: 0.99,
          opacityTo: 0.99,
          stops: [0, 100],
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '80%',
        },
      },
      series: [
        {
          data: this.dataSeries2,
        },
      ],
      xaxis: {
        crosshairs: {
          width: 1,
        },
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        marker: {
          show: false,
        },
      },
      theme: {
        mode: 'light',
      },
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.trafficData) {
      this.getTraffic();
    }
  }

  ngOnInit(): void {
    if(this.userId) {
      this.getDetails()
    }
  }

  getDetails() {
    this.isLoading = true;

    this._api.router.getInstitution(this.userId).subscribe(
      {
        next: (res) => {
          this.institution = res;
          this.isLoading = false;
        },
        error: (e) => {
          this.isLoading = false;
        },
      }
    )
  }

  getTraffic() {
    this.getNewSeries(Number(this.trafficData.tx), Number(this.trafficData.rx));
    this.formatTraffic();
    this.status = this.trafficData.status;
  }

  getInitialSeries() {
    const series: number[] = [];
    for (let i = 0; i < 20; i++) {
      series.push(0);
    }

    return series;
  }

  formatTraffic() {
    this.upload = this.trafficData.textTx;
    this.download = this.trafficData.textRx;
  }


  addItem(series: number[], item: number) {
    series.push(item);
    series.shift();
    return series;
  }

  getNewSeries(serie1: number, serie2: number) {
    this.dataSeries1 = this.addItem(this.dataSeries1, serie1);
    this.dataSeries2 = this.addItem(this.dataSeries2, serie2);

    if(this.conversionsChart1 && this.conversionsChart1.chart) {
      this.conversionsChart1.updateSeries([{ data: this.dataSeries1 }], true);
    }
    if(this.conversionsChart2 && this.conversionsChart2.chart) {
      this.conversionsChart2.updateSeries([{ data: this.dataSeries2 }], true);
    }
  }

  dismiss() {
    this.dismissModal.emit();
  }

  ngOnDestroy() {
    if (this.intervalSub) {
      clearInterval(this.intervalSub);
    }
  }
}
