<div class="modal-header">
  <h4 class="modal-title">Detalles</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="dismiss()"
  ></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-lg-6 col-md-6">
      <div class="card overflow-hidden h-details">
        <div class="card-body">
          <div
            class="d-flex flex-column"
            *ngIf="!isLoading && institution"
          >
            <div class="summary-size-icon">
              <i
                class="fa fa-school text-success"
                *ngIf="status === 'ACTIVO'"
              ></i>
              <i
                class="fa fa-school text-danger"
                *ngIf="status === 'INACTIVO'"
              ></i>
            </div>
            <div class="p-1 fw-bold">{{ institution.name }}</div>
            <div class="p-1">
              <span class="fw-bold">Cod. Modular:</span>
              {{ institution.code }}
            </div>
            <div class="p-1">
              <span class="fw-bold">UGEL:</span>
              {{ institution.userGroupName }}
            </div>
            <div class="p-1">
              <span class="fw-bold">Localidad:</span>
              <ng-container *ngIf="institution.ubigeoDetail">
                {{ institution.ubigeoDetail.district.name }} - {{ institution.ubigeoDetail.province.name }} -
                {{ institution.ubigeoDetail.departament.name }}
              </ng-container>
            </div>
            <div class="p-1">
              <span class="fw-bold">IP:</span>
              {{ institution.ipRouter }}
            </div>
            <div class="p-1">
              Estado:
              <ng-container *ngIf="status === 'ACTIVO'">
                <span class="badge text-bg-success">{{ status }}</span>
              </ng-container>
              <ng-container *ngIf="status === 'INACTIVO'">
                <span class="badge text-bg-danger">{{ status }}</span>
              </ng-container>
            </div>
          </div>
          <app-loading-resource *ngIf="isLoading"></app-loading-resource>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6">
      <div class="card overflow-hidden">
        <div class="card-body">
          <h6>Subida</h6>
          <h4
            class="m-0"
            *ngIf="!isLoading"
          >
            {{ upload }}
            <span class="text-primary">
              <i class="feather icon-arrow-up"></i>
            </span>
          </h4>
          <app-loading-resource *ngIf="isLoading"></app-loading-resource>
        </div>
        <div
          class="mb-2"
          id="conversionsChart1"
          *ngIf="
            !isLoading &&
            conversion1.series &&
            conversion1.chart &&
            conversion1.dataLabels &&
            conversion1.fill &&
            conversion1.plotOptions &&
            conversion1.tooltip &&
            conversion1.xaxis &&
            conversion1.theme
          "
        >
          <apx-chart
            #conversionsChart1
            [series]="conversion1.series"
            [chart]="conversion1.chart"
            [dataLabels]="conversion1.dataLabels"
            [colors]="conversion1Color"
            [fill]="conversion1.fill"
            [plotOptions]="conversion1.plotOptions"
            [tooltip]="conversion1.tooltip"
            [xaxis]="conversion1.xaxis"
            [theme]="conversion1.theme"
          ></apx-chart>
        </div>
      </div>
      <div class="card overflow-hidden">
        <div class="card-body">
          <h6>Bajada</h6>
          <h4
            class="m-0"
            *ngIf="!isLoading"
          >
            {{ download }}
            <span class="text-primary">
              <i class="feather icon-arrow-down"></i>
            </span>
          </h4>
          <app-loading-resource *ngIf="isLoading"></app-loading-resource>
        </div>
        <div
          id="conversionsChart2"
          class="mb-2"
          *ngIf="
            !isLoading &&
            conversion2.series &&
            conversion2.chart &&
            conversion2.dataLabels &&
            conversion2.fill &&
            conversion2.plotOptions &&
            conversion2.tooltip &&
            conversion2.xaxis &&
            conversion2.theme
          "
        >
          <apx-chart
            #conversionsChart2
            [series]="conversion2.series"
            [chart]="conversion2.chart"
            [dataLabels]="conversion2.dataLabels"
            [colors]="conversion2Color"
            [fill]="conversion2.fill"
            [plotOptions]="conversion2.plotOptions"
            [tooltip]="conversion2.tooltip"
            [xaxis]="conversion2.xaxis"
            [theme]="conversion2.theme"
          ></apx-chart>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer"></div>
