import { HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { CreatePayResponse, CreatePayResponseSchema } from 'src/app/models/api-response/customer';
import { ReceiptDetailResponse, ReceiptDetailResponseSchema } from 'src/app/models/api-response/receipt';
import { environment } from 'src/environments/environment';
import { parseResponse } from '../parse-response.operator';
import { BaseApiEndpoint } from './base-endpoint';
import { DataTablesResponse } from 'src/app/models/data-table-response';

export class RouterEndpoint extends BaseApiEndpoint {
  getRouterInterfaces(routerId: number): Observable<any[]> {
    return this._http.get<any>(this.path + 'routers/' + routerId + '/interfaces').pipe(map((res) => res.data));
  }

  getTrafficInRealTimeByInterface(routerId: number, routerInterface: string): Observable<any[]> {
    return this._http
      .get<any>(this.path + 'routers/' + routerId + '/interfaces/' + routerInterface + '/traffic')
      .pipe(map((res) => res.data));
  }

  getTrafficInRealTimeByRouter(routerId: number): Observable<any[]> {
    return this._http.get<any>(this.path + 'routers/' + routerId + '/traffic').pipe(map((res) => res.data));
  }

  getInstitutionTrafficInRealTimeByIp(ip: string, groupId: number): Observable<any[]> {
    return this._http
      .get<any>(this.path + 'ip/' + ip + '/user-groups/' + groupId + '/institution-traffic')
      .pipe(map((res) => res.data));
  }

  getRouterResources(routerId: number): Observable<any> {
    return this._http.get<any>(this.path + 'routers/' + routerId + '/resources').pipe(map((res) => res.data));
  }

  getRouterLogs(routerId: number): Observable<any> {
    return this._http.get<any>(this.path + 'routers/' + routerId + '/logs').pipe(map((res) => res.data));
  }

  getInstitution(userId: number): Observable<any> {
    return this._http.get<any>(this.path + 'institutions/' + userId).pipe(map((res) => res.data));
  }
}
