import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { AppComponent } from './app.component';
import { AuthComponent } from './theme/layout/auth/auth.component';

/* Menu Items */
import { NgbDropdownModule, NgbTooltipModule, NgbModule, NgbCarousel, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NewPageConfigComponent } from './theme/layout/new-page/new-page-config/new-page-config.component';
import { HeaderRightComponent } from './theme/layout/new-page/new-page-header/header-right/header-right.component';
import { NewPageHeaderComponent } from './theme/layout/new-page/new-page-header/new-page-header.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { InterceptorService } from './services/interceptor.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NewPageComponent } from './theme/layout/new-page/new-page.component';
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ChatMsgComponent } from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { InicioComponent } from './components/client/inicio/inicio.component';
import { PerfilComponent } from './components/client/perfil/perfil.component';
import { ReceiptComponent } from './components/client/receipt/receipt.component';
import { ServiciosComponent } from './components/client/servicios/servicios.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DataTablesModule } from 'angular-datatables';
import { NgIconsModule } from '@ng-icons/core';
import { bootstrapChevronRight, bootstrapChevronLeft, bootstrapArrowBarDown } from '@ng-icons/bootstrap-icons';
import { TableComponent } from './components/client/receipt/table/table.component';
import { DownloadReceiptComponent } from './components/client/receipt/table/download-receipt/download-receipt.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { PaymentGatewayComponent } from './components/payment-gateway/payment-gateway.component';
import { ContactComponent } from './components/client/contact/contact.component';
import { InstitutionsComponent } from './components/client/institutions/institutions.component';
import { DashboardInstitutionComponent } from './components/client/dashboard-institution/dashboard-institution.component';
import { NotificationsComponent } from './components/client/notifications/notifications.component';
import { DashboardDedicatedComponent } from './components/client/dashboard-dedicated/dashboard-dedicated.component';
import { InstitutionDetailsComponent } from './components/client/institution-details/institution-details.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    NavigationComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavRightComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    AuthComponent,
    PaymentGatewayComponent,
    NewPageConfigComponent,
    NewPageComponent,
    HeaderRightComponent,
    NewPageHeaderComponent,
    NavLeftComponent,
    NavSearchComponent,
    InicioComponent,
    PerfilComponent,
    ServiciosComponent,
    TableComponent,
    DownloadReceiptComponent,
    ReceiptComponent,
    ContactComponent,
    InstitutionsComponent,
    DashboardInstitutionComponent,
    NotificationsComponent,
    DashboardDedicatedComponent,
    InstitutionDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    HttpClientModule,
    DataTablesModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
    }),
    NgbModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    NgScrollbarModule,
    NgApexchartsModule,
    NgbCarousel,
    NgIconsModule.withIcons({ bootstrapChevronLeft, bootstrapChevronRight, bootstrapArrowBarDown }),
  ],
  providers: [
    NavigationItem,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
